/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    9/29/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import styles from "./Spinner.module.scss";

// Centered spinner that lets the user know something is loading.
export default function Spinner(props: Props): Component {
  return props.loading ? (
    <div className={styles.spinnerContainer}>
      <div role="status" className={`${styles.spinner} spinner-border text-primary mt-5`}>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  ) : null;
}

Spinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};

interface Props {
  loading: boolean;
}
