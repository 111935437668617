/*
Author:      Zachary Thomas
Created:     11/8/2021
Modified:    11/10/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import styles from "./Footer.module.scss";

// General footer.
export default function Footer(): Component {
  const date = new Date();

  return (
    <footer className={`${styles.body} py-1 px-3`}>
      <span className={styles.copyright}>
        &#xA9; {date.getFullYear()}, Cornell Pump Company. All rights reserved.
      </span>
    </footer>
  );
}
