/*
Author:      Zachary Thomas
Created:     5/18/2022
Modified:    5/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import { MAX_PASSWORD_LENGTH } from "../../utilities/constants";
import styles from "./PasswordInput.module.scss";

// Input for storing passwords with visibility toggle button.
export default function PasswordInput(props: Props): Component {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div className={styles.body}>
      <input
        type={showPassword ? "text" : "password"}
        className="login-input form-control my-2"
        aria-describedby="Password"
        placeholder="Enter password"
        maxLength={MAX_PASSWORD_LENGTH}
        value={props.password}
        onChange={(e) => props.onChangePassword(e.target.value)}
      />

      <i
        className={`${styles.toggleVisible} fa fa-fw fa-eye${showPassword ? "" : "-slash"}`}
        onClick={() => setShowPassword((prev) => !prev)}
      />
    </div>
  );
}

PasswordInput.propTypes = {
  password: PropTypes.string.isRequired,
  onChangePassword: PropTypes.func.isRequired,
};

type Props = InferProps<typeof PasswordInput.propTypes>;
