/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    11/18/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";
import styles from "./NavbarLink.module.scss";

// A single clickable link on a navbar.
export default function NavbarLink(props: Props): Component {
  const [targetPage, setTargetPage] = useState<string | undefined | null>("");

  // Treat partial matches as complete matches, unless exact is enabled.
  useEffect(() => {
    if (props.exactMatch) {
      setTargetPage(props.targetPage);
    } else {
      if (props.targetPage === undefined || props.targetPage === null) {
        setTargetPage(props.currentPage);
      } else if (props.targetPage !== null && props.targetPage !== undefined) {
        const targetPageArray = props.targetPage.split("/");
        const currentPageArray = props.currentPage.split("/");

        let pathMatch = true;
        currentPageArray.forEach((subdirectory, i) => {
          if (targetPageArray[i] !== subdirectory) {
            pathMatch = false;
          }
        });

        if (pathMatch) {
          setTargetPage(props.currentPage);
        } else {
          setTargetPage(props.targetPage);
        }
      }
    }
  }, [props.currentPage, props.targetPage, props.exactMatch]);

  return (
    <li className={`${styles.item} mx-3 ${targetPage === props.currentPage ? styles.active : ""}`}>
      <Link className={styles.link} to={targetPage ? targetPage : props.currentPage}>
        <span>{props.name}</span>
      </Link>
    </li>
  );
}

NavbarLink.propTypes = {
  name: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
  targetPage: PropTypes.string,
  exactMatch: PropTypes.bool,
};

type Props = InferProps<typeof NavbarLink.propTypes>;
