/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    7/20/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import Card from "../../components/Card/Card";
import TextBlurb from "../../components/TextBlurb/TextBlurb";
import styles from "./Error404Page.module.scss";

// Page for displaying 404 errors.
export default function Error404Page(): Component {
  return (
    <div className={`${styles.body} p-4`}>
      <Card title="Page Not Found">
        <div className="my-5">
          <TextBlurb
            title="404"
            paragraph="Sorry! The page you are looking for was not found."
            icon="exclamation-triangle"
          />
        </div>
      </Card>
    </div>
  );
}
