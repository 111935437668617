/*
Author:      Zachary Thomas
Created:     11/18/2022
Modified:    4/28/2023

Copyright 2022 - 2023 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect, useReducer } from "react";
import { API, FILTER_TYPES } from "../../utilities/constants";
import useApi from "../../hooks/useApi";
import FilterOptions from "../../components/FilterOptions/FilterOptions";
import MotorsTable from "./MotorTable/MotorsTable";
import deepCopy from "../../utilities/deepCopy";
import Spinner from "../../components/Spinner/Spinner";
import styles from "./MotorPage.module.scss";

// Page for searching for motors.
export default function MotorPage(): Component {
  const initialFilters = {
    horsepowerFeature: { title: "Motor Horsepower", name: "", code: null, options: null },
    speedFeature: { title: "Motor Speed", name: "", code: null, options: null },
    voltageFeature: { title: "Motor Voltage", name: "", code: null, options: null },
    phaseFeature: { title: "Motor Phase", name: "", code: null, options: null },
    serviceFactorFeature: { title: "Motor Service Factor", name: "", code: null, options: null },
    enclosureFeature: { title: "Motor Enclosure", name: "", code: null, options: null },
    shaftExtension: { title: "Shaft Extension Type", name: "", code: null, options: null },
    motorOrientationFeature: { title: "Motor Orientation", name: "", code: null, options: null },
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [parts, setParts] = useState<Part[]>([]);
  const [filterStore, dispatch] = useReducer(filterReducer, initialFilters);

  // Update the title displayed in the browser tab.
  useEffect(() => {
    document.title = "Cornell Configurator";
  }, []);

  // Get bill of materials that match the current filter selections.
  useApi(
    () => {
      const readyToQuery = filtersAreValid();
      setLoading(readyToQuery);
      return readyToQuery;
    },
    {
      method: "POST",
      url: `${API}/search/motor`,
      body: {
        motorHorsepowerFeature: filterStore.horsepowerFeature.code,
        motorSpeedFeature: filterStore.speedFeature.code,
        motorVoltageFeature: filterStore.voltageFeature.code,
        motorPhaseFeature: filterStore.phaseFeature.code,
        motorServiceFactorFeature: filterStore.serviceFactorFeature.code,
        motorEnclosureFeature: filterStore.enclosureFeature.code,
        motorShaftExtensionFeature: filterStore.shaftExtension.code,
        motorOrientationFeature: filterStore.motorOrientationFeature.code,
      },
    },
    async (response: Response, responseBody: ResponseBody) => {
      setLoading(false);
      if (response.ok && responseBody) {
        setParts(responseBody.motors);
        setErrorMessage("");
      } else {
        setParts([]);
        setErrorMessage("Internal server error. Failed to retrieve motors.");
      }
    },
    [JSON.stringify(filterStore)]
  );

  // Check if filters are valid.
  function filtersAreValid(): boolean {
    // Confirm that each filters has a valid entry or no entry at all.
    const filters = Object.keys(filterStore).map((filterKey) => filterStore[filterKey]);
    let atLeastOneFilterIsFilledOut = false;
    let allFiltersAreValid = true;

    filters.forEach((filter) => {
      let matchingOptionFound = false;
      if (filter.options !== null) {
        matchingOptionFound = filter.options.some((option) => option.name === filter.name);
      }
      if (filter.name.length > 0) {
        atLeastOneFilterIsFilledOut = true;
      }
      if (!matchingOptionFound && filter.name.length > 0) {
        setErrorMessage(`A valid ${filter.title.toLowerCase()} must be selected`);
        allFiltersAreValid = false;
      }
    });

    if (!atLeastOneFilterIsFilledOut) {
      setErrorMessage("At least one filter must be selected");
      allFiltersAreValid = false;
    }

    return allFiltersAreValid;
  }

  // Reducer for filter information.
  function filterReducer(state: FilterStore, action: Action): FilterStore {
    switch (action.type) {
      case FILTER_TYPES.SET_FILTERS: {
        const stateDeepCopy = deepCopy(state);
        const validFilterKeys = Object.keys(stateDeepCopy);
        const keyName = action.payload.keyName;
        const filters = action.payload.filters;
        if (keyName !== undefined && filters !== undefined && validFilterKeys.includes(keyName)) {
          stateDeepCopy[keyName].options = filters;
        }
        return stateDeepCopy;
      }

      case FILTER_TYPES.CLEAR_FILTER: {
        const stateDeepCopy = deepCopy(state);
        const validFilterKeys = Object.keys(stateDeepCopy);
        const keyName = action.payload.keyName;

        // See if we can find a match on the current filter.
        if (keyName !== undefined && validFilterKeys.includes(keyName)) {
          stateDeepCopy[keyName].name = "";
        }
        return stateDeepCopy;
      }

      case FILTER_TYPES.CLEAR_ALL_FILTERS: {
        const stateDeepCopy = deepCopy(state);
        const validFilterKeys = Object.keys(stateDeepCopy);
        validFilterKeys.forEach((keyName) => {
          stateDeepCopy[keyName].code = null;
          stateDeepCopy[keyName].name = "";
        });
        setParts([]);
        return stateDeepCopy;
      }

      case FILTER_TYPES.SELECT_FILTER: {
        const stateDeepCopy = deepCopy(state);
        const validFilterKeys = Object.keys(stateDeepCopy);
        const keyName = action.payload.keyName;
        const filterName = action.payload.filterName;

        // See if we can find a match on the current filter.
        if (
          keyName !== undefined &&
          filterName !== undefined &&
          validFilterKeys.includes(keyName)
        ) {
          const selectedFilter = stateDeepCopy[keyName];
          selectedFilter.name = filterName;

          // Look through the filters options to see if there is a match for the current filter name.
          if (selectedFilter.options !== null) {
            selectedFilter.code = null;
            selectedFilter.options.forEach((option) => {
              if (option.name === filterName) {
                selectedFilter.code = option.code;
              }
            });
          }
        }
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  return (
    <div className="p-4 row">
      <Spinner loading={loading} />

      <div className={`${styles.scrollSection} col-12 col-lg-4`}>
        <button
          className="btn btn-primary mb-4 w-100"
          onClick={() =>
            dispatch({
              type: FILTER_TYPES.CLEAR_ALL_FILTERS,
              payload: {},
            })
          }
        >
          Clear all filters
        </button>

        <FilterOptions
          filter={filterStore.horsepowerFeature}
          type="horsepowerFeature"
          endpoint="feature/motorHorsepower"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.speedFeature}
          type="speedFeature"
          endpoint="feature/motorSpeed"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.voltageFeature}
          type="voltageFeature"
          endpoint="feature/motorVoltage"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.phaseFeature}
          type="phaseFeature"
          endpoint="feature/motorPhase"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.serviceFactorFeature}
          type="serviceFactorFeature"
          endpoint="feature/motorServiceFactor"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.enclosureFeature}
          type="enclosureFeature"
          endpoint="feature/motorEnclosure"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.shaftExtension}
          type="shaftExtension"
          endpoint="shaftExtension"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.motorOrientationFeature}
          type="motorOrientationFeature"
          endpoint="feature/motorOrientation"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />
      </div>

      <div className="col-12 col-lg-8">
        <div className={styles.scrollSection}>
          <MotorsTable parts={parts} errorMessage={errorMessage} />
        </div>
      </div>
    </div>
  );
}

interface ResponseBody {
  motors: Part[];
}
