/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    4/18/2022

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes, { InferProps } from "prop-types";
import "./TitleBar.scss";

// Simple bar with title.
export default function TitleBar(props: Props): Component {
  return <div className={`title-bar title-bar-size-${props.size} py-3`}>{props.title}</div>;
}

TitleBar.propTypes = {
  size: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

type Props = InferProps<typeof TitleBar.propTypes>;
