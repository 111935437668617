/*
Author:      Zachary Thomas
Created:     11/8/2022
Modified:    12/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "../../../components/Card/Card";
import Error from "../../../components/Error/Error";
import Success from "../../../components/Success/Success";
import styles from "./BomTable.module.scss";

// BOM Results Table on Main Configurator Page.
export default function BomTable(props: Props): Component {
  const [requestErrorMessage, setRequestErrorMessage] = useState<string>("");
  const [requestSuccessMessage, setRequestSuccessMessage] = useState<string>("");

  // If the filter settings change, clear the success and error messages.
  useEffect(() => {
    setRequestErrorMessage("");
    setRequestSuccessMessage("");
  }, [JSON.stringify(props.filterStore)]);

  return (
    <Card title={`${props.boms.length} BOM Results`}>
      <Fragment>
        {props.boms.length > 0 && props.errorMessage.length === 0 && (
          <table className="table table-sm table-bordered-dark table-hover table-responsive">
            <thead className={styles.header}>
              <tr>
                <th className="col">TB Number</th>
                <th className="col">Description</th>
              </tr>
            </thead>
            <tbody className={styles.body}>
              {props.boms.map((bom) => (
                <tr
                  key={bom.pumpId}
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open(`/bom/${bom.partId}`)}
                >
                  <td>{bom.model}</td>
                  <td>{bom.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {props.boms.length === 0 && props.errorMessage.length === 0 && (
          <div className="text-center py-5">
            <h3>No BOMs found to display</h3>
          </div>
        )}

        {(requestErrorMessage.length > 0 || requestSuccessMessage.length > 0) && (
          <div className="px-4">
            <Error message={requestErrorMessage} />
            <Success message={requestSuccessMessage} />
          </div>
        )}

        {props.errorMessage.length > 0 && (
          <div className="text-center py-5">
            <h3>{props.errorMessage}</h3>
          </div>
        )}
      </Fragment>
    </Card>
  );
}

BomTable.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  boms: PropTypes.array.isRequired,
  filterStore: PropTypes.object.isRequired,
};

interface Props {
  errorMessage: string;
  boms: Bom[];
  filterStore: FilterStore;
}
