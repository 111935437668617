/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    11/8/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes, { InferProps } from "prop-types";
import "./TextBlurb.scss";

// Title, paragraph, and icon text.
export default function TextBlurb(props: Props): Component {
  return (
    <div className="text-blurb mx-auto">
      <h3 className="text-blurb-title">{props.title}</h3>
      <p className="text-blurb-paragraph mb-3 mx-auto">{props.paragraph}</p>
      <i className={`text-blurb-icon fa fa-fw fa-${props.icon} fa-3x`} />
    </div>
  );
}

TextBlurb.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

type Props = InferProps<typeof TextBlurb.propTypes>;
