/*
Author:      Zachary Thomas
Created:     11/7/2022
Modified:    11/23/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, Fragment } from "react";
import { Route, Navigate, Routes, useNavigate, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Error404Page from "./pages/Error404Page/Error404Page";
import Error500Page from "./pages/Error500Page/Error500Page";
import LoginPage from "./pages/LoginPage/LoginPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import MotorPage from "./pages/MotorPage/MotorPage";
import BomPage from "./pages/BomPage/BomPage";
import "./App.scss";

// Handles page routing.
export default function App(): Component {
  const currentPage = `/${useLocation().pathname.split("/")[1]}`;
  const navigate = useNavigate();

  // If the user is not logged in, bring them to the login page.
  useEffect(() => {
    const idToken = localStorage.getItem("idToken");
    if (!idToken || idToken.length === 0) {
      navigate("/login");
    }
  }, []);

  return (
    <Fragment>
      <Navbar currentPage={currentPage} />

      <main>
        <div className="app container">
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />

            <Route path="/login" element={<LoginPage />} />

            <Route path="/dashboard" element={<DashboardPage />} />

            <Route path="/motor" element={<MotorPage />} />

            <Route path="/bom/:partId" element={<BomPage />} />

            <Route path="/error-500" element={<Error500Page />} />

            <Route path="*" element={<Error404Page />} />
          </Routes>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
}
