/*
Author:      Zachary Thomas
Created:     11/7/2022
Modified:    11/7/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Creates a deep copy of an object (arrays are also objects in JS).
export default function deepCopy<Generic>(object: Generic): Generic {
  return JSON.parse(JSON.stringify(object));
}