/*
Author:      Zachary Thomas
Created:     11/8/2022
Modified:    11/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState } from "react";
import Card from "../../../components/Card/Card";
import Error from "../../../components/Error/Error";
import { Link } from "react-router-dom";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import PropTypes, { InferProps } from "prop-types";
import styles from "./LoginForm.module.scss";

// Login form for users.
export default function LoginForm(props: Props): Component {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  // Check if the user pressed enter, if they did we attempt to login.
  function checkPressEnter(e: React.KeyboardEvent) {
    if (e.key === "Enter") {
      props.onSubmit(email, password);
    }
  }

  return (
    <div
      className={`${styles.body} col-12 col-xl-6 offset-xl-3`}
      onKeyPress={(e) => checkPressEnter(e)}
    >
      <Card title="User Login">
        <div className="px-5 mb-4">
          <label className="mt-4">Email</label>

          <input
            type="email"
            className="form-control mt-2 mb-4"
            id="email-text"
            aria-describedby="Email"
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <label className="mt-2">Password</label>

          <PasswordInput
            password={password}
            onChangePassword={(password: string) => setPassword(password)}
          />

          <div className="text-center my-4">
            <button
              type="submit"
              id="submit-login"
              className={`${styles.btn} btn btn-primary`}
              onClick={() => props.onSubmit(email, password)}
            >
              Login
            </button>
          </div>

          <Error message={props.errorMessage} />
        </div>
      </Card>
    </div>
  );
}

LoginForm.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

type Props = InferProps<typeof LoginForm.propTypes>;
