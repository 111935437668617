/*
Author:      Zachary Thomas
Created:     11/23/2022
Modified:    11/23/2022
Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import Card from "../../../components/Card/Card";
import styles from "./MotorsTable.module.scss";

// Part list Table.
export default function MotorsTable(props: Props): Component {
  return (
    <Card title={`${props.parts.length} Motor Results`}>
      <div>
        {props.parts.length > 0 && props.errorMessage.length === 0 && (
          <table className="table table-sm table-bordered-dark table-hover table-responsive">
            <thead className={styles.header}>
              <tr>
                <th className="col">Part Number</th>
                <th className="col">Description</th>
                <th className="col">Price</th>
              </tr>
            </thead>
            <tbody className={styles.body}>
              {props.parts.map((part) => (
                <tr key={part.partId}>
                  <td>{part.model}</td>
                  <td>{part.description}</td>
                  <td>${part.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {props.parts.length === 0 && props.errorMessage.length === 0 && (
          <div className={styles.error}>
            <h3>No motors found to display</h3>
          </div>
        )}

        {props.errorMessage.length > 0 && (
          <div className="text-center py-5">
            <h3>{props.errorMessage}</h3>
          </div>
        )}
      </div>
    </Card>
  );
}

MotorsTable.propTypes = {
  parts: PropTypes.array.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

interface Props {
  parts: Part[];
  errorMessage: string;
}
