/*
Author:      Zachary Thomas
Created:     11/10/2022
Modified:    11/15/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Makes a request to an API.
export default async function apiRequest<T>(
  url: string,
  method: string,
  body: any
): Promise<[Response, T]> {
  let response = null;
  let responseBody = {};

  // Get the current authorization token.
  const authorizerToken = localStorage.getItem("idToken");

  try {
    // Check if there is a body object to send.
    if (body) {
      // Omit the request body log if it contains sensitive information.
      if ("password" in body || "currentPassword" in body || "newPassword" in body) {
        console.log(`Request: ${method} ${url}`, "[Body omitted to hide sensitive information]");
      } else {
        console.log(`Request: ${method} ${url}`, body);
      }

      response = await fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizerToken || "",
        },
      });
    } else {
      // Don't include the body if there is no valid body to send.
      console.log(`Request: ${method} ${url}`);
      response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizerToken || "",
        },
      });
    }

    console.log(`Response:`, response);
    responseBody = await getResponseBody(response);

    if (
      responseBody !== null &&
      typeof responseBody === "object" &&
      "accessToken" in responseBody
    ) {
      console.log("Response Body: [Body omitted to hide sensitive information]");
    } else {
      console.log("Response Body:", responseBody);
    }

    // Check if the response was a 401 or 403 status code.
    // If it was then we will redirect to the login page.
    if (response && (response.status === 401 || response.status === 403)) {
      console.log("An unauthorized request was made. Returning to login page.");
      window.location.href = "/login";
    }
  } catch (e) {
    // If an error occurs while attempting to make a request,
    // create a generic 500 internal server error and log the error.
    console.error(e);
    response = new Response(null, {
      status: 500,
      statusText: "",
      headers: [],
    });
  }

  return [response, responseBody as T];
}

// Attempt to get the response body.
async function getResponseBody<T>(response: Response): Promise<T> {
  const json = await response.text();
  try {
    const object = JSON.parse(json);
    return object;
  } catch (e) {
    return { error: "Response body does not contain valid JSON." } as T;
  }
}
