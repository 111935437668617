/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    8/10/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import styles from "./Error.module.scss";

// Error message.
export default function Error(props: Props): Component {
  return props.message.length > 0 ? (
    <div className={`${styles.text} alert alert-danger`} role="alert">
      {props.message}
    </div>
  ) : null;
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
};

interface Props {
  message: string;
}
