/*
Author:      Zachary Thomas
Created:     11/7/2022
Modified:    11/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Hook for making an API call.
export default function useApi(
  openingFunction: () => boolean,
  apiRequest: ApiRequest,
  closingFunction: (response: Response, responseBody: any) => void,
  dependencies: any
): void {
  const authorizerToken = localStorage.getItem("idToken");
  const navigate = useNavigate();

  useEffect(() => {
    let ignore = false;
    const controller = new AbortController();

    // Attempt to get the response body.
    async function getResponseBody<T>(response: Response): Promise<T> {
      const json = await response.text();
      try {
        const object = JSON.parse(json);
        return object;
      } catch (e) {
        return { error: "Response body does not contain valid JSON." } as T;
      }
    }

    // Make API call.
    async function callApi(apiRequest: ApiRequest): Promise<void> {
      // Function to call before API call.
      // Only continue if the function returns true.
      let proceed = false;
      if (!ignore) {
        proceed = await openingFunction();
      }

      if (!proceed) {
        return;
      }

      // Perform API call.
      // Takes 'method', 'url', 'body', and 'authorization'.
      let response: Response = new Response(null, { status: 500 });

      let responseBody = null;
      try {
        if (!ignore) {
          if (!apiRequest.method) {
            throw "apiRequest must have a method";
          } else if (!apiRequest.url) {
            throw "apiRequest must have a url";
          }

          // Check if there is a body object to send.
          if (apiRequest.body) {
            // Omit the request body log if it contains sensitive information.
            if (
              "password" in apiRequest.body ||
              "currentPassword" in apiRequest.body ||
              "newPassword" in apiRequest.body
            ) {
              console.log(
                `Request: ${apiRequest.method} ${apiRequest.url}`,
                "[Body omitted to hide sensitive information]"
              );
            } else {
              console.log(`Request: ${apiRequest.method} ${apiRequest.url}`, apiRequest.body);
            }

            response = await fetch(apiRequest.url, {
              signal: controller.signal,
              method: apiRequest.method,
              body: JSON.stringify(apiRequest.body),
              headers: {
                "Content-Type": "application/json",
                Authorization: authorizerToken || "",
              },
            });
          } else {
            // Don't include the body if there is no valid body to send.
            console.log(`Request: ${apiRequest.method} ${apiRequest.url}`);
            response = await fetch(apiRequest.url, {
              signal: controller.signal,
              method: apiRequest.method,
              headers: {
                "Content-Type": "application/json",
                Authorization: authorizerToken || "",
              },
            });
          }

          console.log(`Response:`, response);
          responseBody = await getResponseBody(response);

          if (
            responseBody !== null &&
            typeof responseBody === "object" &&
            "accessToken" in responseBody
          ) {
            console.log("Response Body: [Body omitted to hide sensitive information]");
          } else {
            console.log("Response Body:", responseBody);
          }
        }
      } catch (e) {
        if (e instanceof DOMException) {
          console.log("Response: HTTP request aborted.");
        } else {
          console.error(e);
        }
      }

      // Check if the response was a 401 or 403 status code.
      // If it was then we will redirect to the login page.
      if (response && (response.status === 401 || response.status === 403)) {
        console.log("An unauthorized request was made. Returning to login page.");
        navigate("/login");
      } else {
        // Function to call after API call.
        if (!ignore) {
          closingFunction(response, responseBody);
        }
      }
    }

    callApi(apiRequest);

    return () => {
      ignore = true;
      controller.abort();
    };
  }, dependencies);
}

interface ApiRequest {
  method: RequestMethod;
  url: string;
  body?: any;
}
