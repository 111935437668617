/*
Author:      Zachary Thomas
Created:     11/8/2022
Modified:    11/23/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { API } from "../../utilities/constants";
import Spinner from "../../components/Spinner/Spinner";
import PartsTable from "./PartsTable/PartsTable";

// Page where details about the BOM are shown.
export default function BomPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [parts, setParts] = useState<Part[]>([]);
  const [model, setModel] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { partId } = useParams<Params>();

  // Update the title displayed in the browser tab.
  useEffect(() => {
    document.title = String(model);
  }, [model]);

  // Get part information for the current bill of materials.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/partlist/${partId}`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setModel(responseBody.model);
        setDescription(responseBody.description);
        setParts(responseBody.parts);
      } else {
        setErrorMessage("Internal server error. Please try again later.");
      }
      setLoading(false);
    },
    [partId]
  );

  return (
    <div className="p-4">
      <Spinner loading={loading} />
      <div>
        <PartsTable
          title={`Bill of Materials for ${model} (${description})`}
          parts={parts}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
}

type Params = {
  partId: string;
};

interface ResponseBody {
  model: string;
  description: string;
  parts: Part[];
}
