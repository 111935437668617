/*
Author:      Zachary Thomas
Created:     11/17/2022
Modified:    11/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect } from "react";
import { FILTER_TYPES } from "../../utilities/constants";
import PropTypes from "prop-types";
import styles from "./FilterWrapper.module.scss";

// A conditionally rendered wrapper around one or more filter option lists.
export default function FilterWrapper(props: Props): Component {
  const [fieldsCleared, setFieldsCleared] = useState(false);

  // If this wrapper is hidden, clear all child input fields.
  useEffect(() => {
    if (!props.showWrapper && !fieldsCleared) {
      props.childTypes.forEach((childType) =>
        props.onAction({ type: FILTER_TYPES.CLEAR_FILTER, payload: { keyName: childType } })
      );
      setFieldsCleared(true);
    }
  }, [props.childTypes, props.showWrapper, fieldsCleared]);

  // If this wrapper is visible, turn back on the setting to clear fields once it is hidden next.
  useEffect(() => {
    if (props.showWrapper) {
      setFieldsCleared(false);
    }
  }, [props.showWrapper]);

  return props.showWrapper ? (
    <div className="accordion">
      <div className={`${styles.body} accordion-item`}>
        <div className={`${styles.title} accordion-header`}>
          <button
            className={`${styles.header} accordion-button collapsed`}
            data-bs-toggle="collapse"
            data-bs-target={`#accordionWrapper${props.title.replace(" ", "")}`}
            aria-expanded="true"
            aria-controls={`accordionWrapper${props.title.replace(" ", "")}`}
          >
            {props.title}
          </button>
        </div>
        <div
          id={`accordionWrapper${props.title.replace(" ", "")}`}
          className="accordion-collapse collapse"
          aria-labelledby={`accordionWrapper${props.title.replace(" ", "")}`}
          data-bs-parent={`#accordionWrapper${props.title.replace(" ", "")}`}
        >
          <div className={`${styles.inputContainer} collapsed`}>
            <div className={styles.border}>{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

FilterWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  showWrapper: PropTypes.bool.isRequired,
  childTypes: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
  children: PropTypes.any,
};

interface Props {
  title: string;
  showWrapper: boolean;
  childTypes: string[];
  onAction: (action: Action) => void;
  children: Component | Component[] | null;
}
