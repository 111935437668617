/*
Author:      Zachary Thomas
Created:     11/8/2022
Modified:    11/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect } from "react";
import LoginForm from "./LoginForm/LoginForm";
import Spinner from "../../components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import apiRequest from "../../utilities/apiRequest";
import { API } from "../../utilities/constants";
import styles from "./LoginPage.module.scss";

// Page for users to login to their account.
export default function LoginPage(): Component {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Clear login data when directed to this page.
  useEffect(() => {
    localStorage.removeItem("idToken");
  }, []);

  // Attempts to login a user.
  async function login(emailAddress: string, password: string): Promise<void> {
    if (emailAddress.length === 0) {
      setErrorMessage("Please enter an email address.");
    } else if (password.length === 0) {
      setErrorMessage("Please enter a password.");
    } else {
      const requestBody = {
        username: emailAddress,
        password: password,
      };

      setLoading(true);
      const [response, responseBody]: [response: Response, responseBody: ResponseBody] =
        await apiRequest(`${API}/authenticate`, "POST", requestBody);
      setLoading(false);

      if (response.ok && responseBody) {
        localStorage.setItem("idToken", responseBody.token);
        navigate("/dashboard");
      } else {
        setErrorMessage("Unable to complete login.");
      }
    }
  }

  return (
    <div className={`${styles.body} p-4`}>
      <Spinner loading={loading} />

      <LoginForm
        errorMessage={errorMessage}
        onSubmit={(emailAddress, password) => login(emailAddress, password)}
      />
    </div>
  );
}

interface ResponseBody {
  token: string;
}
