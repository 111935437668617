/*
Author:      Zachary Thomas
Created:     11/8/2022
Modified:    12/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect, Fragment } from "react";
import { API, FILTER_TYPES } from "../../utilities/constants";
import useApi from "../../hooks/useApi";
import Spinner from "../Spinner/Spinner";
import PropTypes from "prop-types";
import styles from "./FilterOptions.module.scss";

// A list of filter options.
export default function FilterOptions(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [matchFound, setMatchFound] = useState<boolean>(true);

  // Get the current filter's options.
  useApi(
    () => {
      setLoading(props.filter.options === null);
      return props.filter.options === null;
    },
    {
      method: "GET",
      url: `${API}/filter/${props.endpoint.toLowerCase()}`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      setLoading(false);
      if (response.ok && responseBody) {
        props.onAction({
          type: FILTER_TYPES.SET_FILTERS,
          payload: { keyName: props.type, filters: responseBody.filters },
        });
      } else {
        console.error("Failed to retrieve filter data.");
      }
    },
    [JSON.stringify(props.filter.options), props.type]
  );

  // Check if the current value matches one of the filter options.
  useEffect(() => {
    setMatchFound(props.filter.code !== null || props.filter.name.length === 0);
  }, [JSON.stringify(props.filter)]);

  // If this list of filter options is hidden, then clear any entered values.
  useEffect(() => {
    if (!props.showOptions) {
      props.onAction({ type: FILTER_TYPES.CLEAR_FILTER, payload: { keyName: props.type } });
    }
  }, [props.showOptions]);

  return props.showOptions ? (
    <Fragment>
      {props.smallContainer ? (
        <div>
          {/* This is a list of filter options inside a wrapper with multiple options. */}
          <Spinner loading={loading} />
          <div className={styles.smallBody}>
            <div className={styles.smallTitle}>
              <span>{props.filter.title}</span>
            </div>

            <div className={styles.smallInputContainer}>
              <input
                className="form-control mx-auto"
                type="text"
                list={`datalist${props.type}`}
                autoComplete="off"
                value={props.filter.name}
                onChange={(e) =>
                  props.onAction({
                    type: FILTER_TYPES.SELECT_FILTER,
                    payload: { keyName: props.type, filterName: e.target.value },
                  })
                }
              />

              {!matchFound && (
                <p className={styles.error}>Invalid {props.filter.title.toLowerCase()}</p>
              )}
            </div>

            <datalist id={`datalist${props.type}`}>
              {props.filter.options !== null &&
                props.filter.options.map((option) => (
                  <Fragment key={option.name}>
                    <option value={option.name} />
                  </Fragment>
                ))}
            </datalist>
          </div>
        </div>
      ) : (
        <div className="accordion">
          {/* This is a single set of filter options that supports an accordion. */}
          <Spinner loading={loading} />
          <div className={`${styles.body} accordion-item`}>
            <div className={`${styles.title} accordion-header`}>
              <button
                className={`${styles.header} accordion-button collapsed`}
                data-bs-toggle="collapse"
                data-bs-target={`#accordion${props.type}`}
                aria-expanded="true"
                aria-controls={`accordion${props.type}`}
              >
                {props.filter.title}
              </button>
            </div>

            <div
              id={`accordion${props.type}`}
              className="accordion-collapse collapse"
              aria-labelledby={`accordion${props.type}`}
              data-bs-parent={`#accordion${props.type}`}
            >
              <div className={`${styles.inputContainer} collapsed`}>
                <input
                  className="form-control mx-auto"
                  type="text"
                  list={`datalist${props.type}`}
                  autoComplete="off"
                  value={props.filter.name}
                  onChange={(e) =>
                    props.onAction({
                      type: FILTER_TYPES.SELECT_FILTER,
                      payload: { keyName: props.type, filterName: e.target.value },
                    })
                  }
                />

                {!matchFound && (
                  <p className={styles.error}>Invalid {props.filter.title.toLowerCase()}</p>
                )}
              </div>
            </div>

            <datalist id={`datalist${props.type}`}>
              {props.filter.options !== null &&
                props.filter.options.map((option) => (
                  <Fragment key={option.name}>
                    <option value={option.name} />
                  </Fragment>
                ))}
            </datalist>
          </div>
        </div>
      )}
    </Fragment>
  ) : null;
}

FilterOptions.propTypes = {
  filter: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  showOptions: PropTypes.bool.isRequired,
  smallContainer: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  filter: Filter;
  type: string;
  endpoint: string;
  showOptions: boolean;
  smallContainer?: boolean;
  onAction: (action: Action) => void;
}

interface ResponseBody {
  filters: Option[];
}
