/*
Author:      Zachary Thomas
Created:     11/23/2022
Modified:    11/23/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./PartRow.module.scss";

// A single part in a table of parts.
export default function PartRow(props: Props): Component {
  const [indentationString, setIndentationString] = useState("0rem");
  const [expanded, setExpanded] = useState(false);

  // Get custom indentation offset.
  useEffect(() => {
    setIndentationString(`${props.indentationLevel * 3}rem`);
  }, [props.indentationLevel]);

  return (
    <Fragment>
      <div
        className={`${styles.body} py-2`}
        style={{ marginLeft: indentationString }}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <div className="row align-items-center">
          <div className="col-1 text-center">
            {props.part.parts !== undefined && props.part.parts.length > 0 && expanded && (
              <span>&#9660;</span>
            )}
            {props.part.parts !== undefined && props.part.parts.length > 0 && !expanded && (
              <span>&#9654;</span>
            )}
          </div>

          <div className="col">
            Part number: <b>{props.part.model}</b>
          </div>

          <div className="col">
            Description: <b>{props.part.description}</b>
          </div>

          <div className="col">
            Quantity: <b>{props.part.quantity}</b>
          </div>

          <div className="col">
            Price: <b>${props.part.price}</b>
          </div>
        </div>
      </div>

      {expanded &&
        props.part.parts !== undefined &&
        props.part.parts.map((part) => (
          <PartRow
            key={`${part.parentModel}_${part.partId}`}
            part={part}
            indentationLevel={props.indentationLevel + 1}
          />
        ))}
    </Fragment>
  );
}

PartRow.propTypes = {
  part: PropTypes.object.isRequired,
  indentationLevel: PropTypes.number.isRequired,
};

interface Props {
  part: Part;
  indentationLevel: number;
}
