/*
Author:      Zachary Thomas
Created:     11/18/2022
Modified:    11/23/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import Card from "../../../components/Card/Card";
import PartRow from "./PartRow/PartRow";
import styles from "./PartsTable.module.scss";

// Part list Table.
export default function PartsTable(props: Props): Component {
  return (
    <Card title={props.title}>
      <div>
        {props.parts.length > 0 &&
          props.errorMessage.length === 0 &&
          props.parts.map((part) => (
            <PartRow key={`${part.parentModel}-${part.partId}`} part={part} indentationLevel={0} />
          ))}

        {props.parts.length === 0 && props.errorMessage.length === 0 && (
          <div className={styles.error}>
            <h3>No parts found to display</h3>
          </div>
        )}

        {props.errorMessage.length > 0 && (
          <div className="text-center py-5">
            <h3>{props.errorMessage}</h3>
          </div>
        )}
      </div>
    </Card>
  );
}

PartsTable.propTypes = {
  title: PropTypes.string.isRequired,
  parts: PropTypes.array.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

interface Props {
  title: string;
  parts: Part[];
  errorMessage: string;
}
