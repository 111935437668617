/*
Author:      Zachary Thomas
Created:     11/18/2022
Modified:    11/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import NavbarLink from "../NavbarLink/NavbarLink";
import styles from "./Navbar.module.scss";

// General navbar.
export default function Navbar(props: Props): Component {
  const navigate = useNavigate();

  // logout and return the login screen.
  function logout() {
    localStorage.removeItem("idToken");
    navigate("/login");
  }

  return (
    <nav className={`${styles.body} navbar navbar-expand-xl px-3`}>
      <div className="row align-items-center w-100">
        <div className="navbar-start col-auto">
          <div className={styles.title} onClick={() => navigate("/dashboard")}>
            Cornell Configurator
          </div>
        </div>

        {props.currentPage !== "/login" && (
          <div className="col">
            <NavbarLink name="BOM Search" targetPage="/dashboard" currentPage={props.currentPage} />

            <NavbarLink name="Motor Search" targetPage="/motor" currentPage={props.currentPage} />
          </div>
        )}

        {props.currentPage !== "/login" && (
          <div className="col">
            <button className="btn btn-light float-end" onClick={() => logout()}>
              Sign Out
            </button>
          </div>
        )}
      </div>
    </nav>
  );
}

Navbar.propTypes = {
  currentPage: PropTypes.string.isRequired,
};

interface Props {
  currentPage: string;
}
