/*
Author:      Zachary Thomas
Created:     11/7/2022
Modified:    12/23/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

let api = "https://configurator.api.rpm-squared-dev.com";
if (process.env.REACT_APP_ENV === "production") {
  api = "https://configurator.api.rpm-squared.com";
}

// Constants that are referenced throughout the application.
export const API = api;
export const USER_ROLE = "USER";
export const ADMIN_ROLE = "ADMIN";
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 90;
export const FILTER_TYPES = {
  SET_FILTERS: "SET_FILTERS",
  SELECT_FILTER: "SELECT_FILTER",
  CLEAR_FILTER: "CLEAR_FILTER",
  CLEAR_ALL_FILTERS: "CLEAR_ALL_FILTERS",
};
